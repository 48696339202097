import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/records",
      name: "records",
      component: () => import("@/components/v-records-list")
    },
    {
      path: "/record-form",
      //alias: "/record-form",
      name: "record-form",
      props: true,
      component: () => import("@/components/v-record-form")
    },
    {
      path: "/login",
      //alias: "/login",
      name: "login",
      component: () => import("@/components/login")
    },
    {
      path: "/about",
      //alias: "/user",
      name: "about",
      component: () => import("@/components/about")
    },
/*    {
      path: "/output",
      alias: "/output",
      name: "output",
      component: () => import("@/components/output")
    },*/
  ]
});
