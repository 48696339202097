// eslint-disable-next-line no-unused-vars;
import axios from "axios";

/*export default axios.create({
  baseURL: "http://localhost:8080/api", // ��� ��� localhost
  headers: {
    "Content-type": "application/json"
  }
});*/


export default axios.create({
  baseURL: "https://config1.ru/api", // ��� ��� config1.ru
  headers: {
    "Content-type": "application/json"
  }
});
