
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'

Vue.config.productionTip = false

Vue.component("modal-window", {
  template: "#modal-template"
});

//Vue.component("ModalWindow0", { template: "ModalWindow0" });
//Vue.component("ModalWindow0", ModalWindow0);


new Vue({
  render: h => h(App),
  router,
  store,
  data:{
    //name: '',
    //password: '',
  },
  mounted() {
    /*if (localStorage.name) {
      this.name = localStorage.name;
    }
    if (localStorage.password) {
      //this.password = localStorage.password;
    }*/
  },
  methods: {
/*    persist() {


      if (this.name != "" && this.password !=""){
        localStorage.name = this.name;
        this.CHECK_USER(localStorage.name);
      }

      //localStorage.password = this.password;
    },*/
  },
}).$mount('#app')
