
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app"
};
</script>

<style type="text/css">
  /* подключение шрифтов */
  @font-face {
    font-family: "Roboto";
    font-weight: 100;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Thin.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Thin.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 300;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Light.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Light.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Regular.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Regular.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 500;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Medium.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Medium.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Bold.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Bold.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 800;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Medium.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Medium.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto", condensed;
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-Condensed.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-Condensed.woff2") format("woff2");
  }
  @font-face {
    font-family: "Roboto";
    font-weight: 700;
    font-style: normal;
    font-display: auto;
    unicode-range: U+000-5FF;
    src: local("Roboto"), url("./assets/fonts/Roboto/ttf/Roboto-BoldCondensed.ttf") format("truetype"),
                          url("./assets/fonts/Roboto/woff/Roboto-BoldCondensed.woff2") format("woff2");
  }
  /* common styles */
  * {
    font-family: "Roboto", sans-serif;
    color: #191919;
  }
  body {
    margin: 0;
    background: #f6f5f0;/* фон вокруг таблицы*/
  }
  h1 {
    font-size: 24px; /*32*/
    font-weight: 300;
  }
  h3 {
    font-size: 18px;
    font-weight: 300;
  }
  .c-buttons-row--container {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff; /* фон вокруг кнопок */
    padding: 20px;
  }
  .c-buttons-row--container-after {
    position: absolute;
    display: block;
    content: "";
    bottom: 0;
    left: 0%;
    width: 100%;
    height: 4px;
    /*background: #788d98;*/
    background: #ff0000; /* линия - разделитель между кнопками и таблицей */
    box-shadow: 0 0 1px 0 rgba(0,0,0,.1),0 1px 1px 0 rgba(0,0,0,.18);
  }
  .c-line-table-catalog-between {
    /*position: absolute;*/
    display: block;
    content: "";
    /*bottom: 0;*/
    left: 0%;
    width: 100%;
    height: 4px;
    background: #8b8b8b; /* линия - разделитель между таблицей и каталогом */
    box-shadow: 0 0 1px 0 rgba(0,0,0,.1),0 1px 1px 0 rgba(0,0,0,.18);
  }
  .c-buttons-row {
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin: auto;
  }
  .c-button {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin: 0 15px;
    cursor: pointer;
    width: 65px;
  }
  .c-button__img {
    width: 30px;
    height: 30px;
  }
  .c-button-img__filling {
    fill: #C9C9C9; /* заливка не активной кнопки */
    transition: fill .3s;
  }
  .c-button:hover .c-button-img__filling {
    fill: rgba(255, 195, 41, .6); /* заливка активной кнопки */
  }
  .c-button__text {
    padding: 0 5px;
    color: #555; /* цвет подписей кнопок */  
    font-weight: 300;
    font-size: 13px;
  }
  .c-container {
    padding: 0px 20px 20px;
  }
  .container2 {
    align-content: center;
    display: flex;
    font-size: 25px; /*25*/
  }
  .main-menu {
    margin: 0px 15px 0px 15px ;
  }

  .login-menu {
    display: flexbox;
    /*justify-content: space-between;*/
    width: max-content;
    margin: auto;
  }
  .login-content {
    margin: 10px 0px 10px 0px ;
    justify-content: space-between;

  }
</style>
